<template>
  <div class="page1">
    <div class="content">
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData();
        "
      >
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Event_Name')">
                <el-input v-model="formInline.discountName" placeholder="请输入活动名称" />
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <el-autocomplete
                  v-model="modelvalue"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model="formInline.status" clearable>
                  <el-option label="全部" value="" />
                  <el-option label="未开始" :value="1" />
                  <el-option label="进行中" :value="2" />
                  <el-option label="已结束" :value="3" />
                  <el-option label="已下架" :value="4" />
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                icon="el-icon-search"
                type="primary"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</AuthorityComponent
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.create']"
                icon="el-icon-plus"
                type="primary"
                @click="$router.push({ path: '/yardDiscountEdit' })"
                >添加</AuthorityComponent
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column :label="$t('searchModule.Event_Name')" align="center">
            <template slot-scope="scope">
              {{ scope.row.discountName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.park_name')" align="center">
            <template slot-scope="scope">
              {{ scope.row.parkName }}
            </template>
          </el-table-column>
          <el-table-column label="折扣" align="center">
            <template slot-scope="scope"> {{ scope.row.discount }}% </template>
          </el-table-column>
          <el-table-column label="活动时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.startTime | timeFileter }} 至 {{ scope.row.endTime | timeFileter }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.state')" align="center">
            <template slot-scope="scope">
              {{ scope.row.status | statusFilter }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Update_time')" align="center">
            <template slot-scope="scope">
              {{ scope.row.updatedTime }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            v-if="!(!buttons.view && !buttons.edit && !buttons.down && !buttons.delete)"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.view']"
                    :command="1"
                    >查看</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.edit']"
                    v-if="scope.row.status === 1"
                    :command="2"
                    >编辑</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.down']"
                    v-if="scope.row.status === 2"
                    :command="3"
                    >下架</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.delete']"
                    v-if="scope.row.status === 1"
                    :command="4"
                    >删除</AuthorityComponent
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "yardDiscount",
  filters: {
    timeFileter(val) {
      if (!val) return;
      return val.split(" ")[0];
    },
    statusFilter(val) {
      switch (val) {
        case 1:
          return "未开始";
        case 2:
          return "进行中";
        case 3:
          return "已结束";
        case 4:
          return "已下架";
        default:
          return "";
      }
    },
  },
  data() {
    return {
      formInline: {
        discountName: "",
        parkId: "",
        status: "",
      },
      tableData: [],
      loading: false,
      total: 0,
      pageNum: 1,
      pageSize: 15,
      modelvalue: "",
      temParkData: [],
      buttons: this.$route.meta.authority.button,
    };
  },
  methods: {
    searchData() {
      this.loading = true;
      const tempData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        discountName: this.formInline.discountName,
        parkId: this.formInline.parkId,
        status: this.formInline.status,
      };
      if (!this.modelvalue) {
        tempData.parkId = "";
      }
      const opt = {
        method: "get",
        url: "/acb/2.0/parkDiscount/list",
        data: tempData,
        success: (res) => {
          this.tableData = res.value.list;
          this.total = res.value.total * 1;
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    lowerShelf(row) {
      const opt = {
        method: "post",
        url: "/acb/2.0/parkDiscount/unload/" + row.discountId,
        data: {},
        success: (res) => {
          this.searchData();
        },
      };
      this.$confirm("您确定要下架该活动吗?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(opt);
        })
        .catch(() => {});
    },
    deleteItem(row) {
      const opt = {
        method: "post",
        url: "/acb/2.0/parkDiscount/delete/" + row.discountId,
        data: {},
        success: (res) => {
          this.searchData();
        },
      };
      this.$confirm("您确定要删除该活动吗?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(opt);
        })
        .catch(() => {});
    },
    // 操作细则
    handleCommand(command, row) {
      switch (command) {
        case 1:
          this.$router.push({ path: "/yardDiscountDetail", query: { discountId: row.discountId } });
          break;
        case 2:
          this.$router.push({ path: "/yardDiscountEdit", query: { discountId: row.discountId } });
          break;
        case 3:
          this.lowerShelf(row);
          break;
        case 4:
          this.deleteItem(row);
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.searchData();
  },
  activated() {
    this.searchData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
